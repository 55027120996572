@charset "UTF-8";
@import "vars.scss";
.page_agency_cat {
  .lead {
    margin-top: 7rem;
    text-align: center;
    h2 {
      font-size: 1.8rem;
      font-weight: 700;
    }
    h2+p {
      color: #3c3c3c;
      width: 74rem;
      margin: 2rem auto 0;
      @include mq() {
        width: 90%;
      }
    }
  }
  .inner {
    width: 100rem;
    margin: 0 auto;
    @include mq() {
      width: 100%;
    }
  }
  .post_list {
    flex-wrap: wrap;
    margin: 6rem auto;
    @media screen and (max-width: 1290px) {
      max-width: 96%;
    }
    @include mq() {
      width: 31rem;
      margin: 6rem auto 0;
    }
    &:after {
      content: "";
      display: block;
      width: 31%;
      height: 0;
    }
  }
  .list_box {
    border: 1px solid #707070;
    border-radius: 10em;
    height: 31rem;
    width: 31rem;
    margin-bottom: 12rem;
    position: relative;
    @include mq($breakpoint: lg) {
      height: 30rem;
      width: 30rem;
    }
    .ttl {
      font-weight: 700;
      margin-top: 1rem;
      width: 100%;
      text-align: center;
    }
    .center_img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 21rem;
      height: 21rem;
      transform: translate(-50%, -50%);
      img {
        width: 100%;
        height: 100%;
        border: 1px solid #707070;
        border-radius: 10em;
      }
    }
    .cloud_wrap {
      position: absolute;
      bottom: -8rem;
      left: 50%;
      transform: translateX(-50%);
      .en, .posi {
        font-size: 1.3rem;
        display: inline-block;
      }
      .posi {
        margin-top: 1.4rem;
      }
      .name {
        font-size: 2.5rem;
        display: inline-block;
      }
    }
    .bg_cloud {
      padding: 1.2rem 3rem;
      line-height: 1.2;
    }
  } //list_box
}

.page_agency {
  .inner1000 {
    width: 100rem;
    margin: 0 auto;
    @include mq() {
      width: 100%;
    }
  }
  .inner740 {
    width: 74rem;
    margin: 0 auto;
    @include mq() {
      width: 90%;
    }
  }
  .ttl {
    font-size: 2rem;
    font-weight: 700;
  }
  .ttl_border {
    border-left: 6px solid #5D5D5D;
    line-height: 1.4;
    padding-left: 1rem;
    margin: 4rem 0 3rem;
  }
  .fnt_s {
    font-size: 1.4rem;
  }
  .intro {
    .common_box {
      .btn_wrap {
        text-align: left;
      }
      .left {
        width: 40rem;
        height: 40rem;
        left: 0;
        img {
          height: 100%;
          object-fit: cover;
        }
        @include mq() {
          width: 27rem;
          height: 27rem;
          left: 2rem;
        }
      }
      .right {
        width: calc(100% - 40rem);
        right: 0;
        @include mq() {
          width: 90%;
          margin: 0 auto;
        }
      }
      .flex {
        margin: 10rem 0 0;
        @include mq() {
          margin: 5rem 0 0;
        }
      }
      .ttl {
        padding: 0 2rem 2rem 5rem;
        @include mq() {
          word-break: normal;
          text-align: left;
          padding: 0 0 2rem;
        }
      }
      .ttl+p {
        margin: 3rem 0 0 5rem;
        @include mq() {
          margin: 3rem 0 0;
         }
      }
      .bg_cloud {
        top: -4rem;
        left: -1rem;
        @include mq() {
          top: -4rem;
          left: 2rem;
         }
        .txt {
          padding: 2.6rem 0 0 4rem;
          line-height: 1.4;
          span {
            display: inline-block;
          }
        }
        .en, .posi {
          font-size: 1.4rem;
        }
        .posi {
          margin-top: 1.6rem;
          @include mq() {
            margin-top: 1rem;
          }
        }
        .name {
          font-size: 3rem;
        }
      } //bg_cloud
    } //common_box
    .skill_box {
      background: #fff;
      border: 1px solid #707070;
      margin-top: 3rem;
      padding: 5rem 4rem 5rem 8rem;
      @include mq() {
        padding: 3rem 2rem 3rem 2rem;;
       }
    }
    .skill_wrap {
      margin-top: 3rem;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          border: 1px solid #707070;
          border-radius: 3em;
          font-size: 1.4rem;
          margin: 1rem 1rem 0 0;
          padding: 0 1rem;
        }
      }
    }
  } //intro
  .comme_career {
    margin-top: -16rem;
  }
  .skewed_bg {
    padding: 16rem 0;
    &:before {
      bottom: 0%;
      height: 92%;
      background: rgba(230,230,230,.6);
      @include mq() {
        height: 96%;
      }
    }
    &:after {
      bottom: -30%;
      height: 40%;
      background: #fff;
      @include mq() {
      }
    }
  } //skewed_bg
  .consult_design {
    .posi {
      margin-top: 1rem;
    }
    .name {
      font-size: 3rem;
    }
  }
  .pagenavi {
    margin: 8rem auto 10rem;
  }
  .works {
    .work_ttl {
      font-weight: 700;
      margin-top: 5rem;
    }
    .work_cont {
      border: 1px solid #707070;
      padding: 2rem;
      min-width: 35rem;
      margin-top: 1rem;
      width: 100%;
      @include mq() {
        min-width: inherit;
      }
      &+img {
        align-self: flex-start;
        height: auto;
        max-width: 35rem;
        margin: 1rem 0 0 3.5rem;
        @include mq() {
          max-width: 100%;
          margin-left: 0;
          margin-top: 1rem;
        }
      }
    }
  } //works
  .swiper-container {
    height: 65rem;
    margin-top: 4.8rem;
    padding-bottom: 9rem;
    @include mq() {
      height: 36rem;
      margin-top: 2rem;
    }
    img {
      object-fit: cover;
      height: 100%;
    }
  }
  .swiper-container-horizontal {
    &>.swiper-pagination-bullets {
      bottom: 3.4rem;
      .swiper-pagination-bullet {
        margin: 0 8px;
      }
    } //swiper-pagination-bullets
  }
} //page_agency

