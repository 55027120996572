@charset "UTF-8";
@import "vars.scss";
.swiper-pagination-bullet {
  background: #d9d9d9;
  width: 12px;
  height: 12px;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background: #696969;
}

.fv {
  position: relative;
  .cloud_wrap {
    position: absolute;
    bottom: 7rem;
    right: 10rem;
    z-index: 1;
    @include mq() {
      bottom: 9rem;
      right: 0;
      left: 0;
    }
  }
  .bg_cloud {
    @include mq() {
      margin: 0 auto;
    }
    .txt {
      font-size: 1.8rem;
      top: 2rem;
      left: 4rem;
      line-height: 2;
    }
  }
}

.page_top {
  .inner {
    max-width: 99rem;
    margin: 0 auto;
    @include mq() {
      max-width: inherit;
    }
  }
  .swiper-container {
    padding-bottom: 9rem;
    @include mq() {
      padding-bottom: 18rem;
    }
    img {
      width: 100%;
      @media screen and (min-width: 1280px) {
        height: 56rem;
        object-fit: cover;
      }
    }
    .swiper-slide.item01 {
      position: relative;
      .txt {
        color: #fcfcfc;
        font: bold 2.6rem Roboto;
        letter-spacing: 2.6px;
        position: absolute;
        top: 46%;
        left: 50%;
        transform: translateX(-50%);
        text-shadow: 0 2px 2px rgba(0,0,0,.16);
        white-space: nowrap;
        @include mq() {
          top: 32%;
          text-align: center;
          white-space: normal;
          width: 80%
        }
      }
    }
  }
  .swiper-container-horizontal {
    &>.swiper-pagination-bullets {
      bottom: 3.4rem;
      .swiper-pagination-bullet {
        margin: 0 8px;
      }
    } //swiper-pagination-bullets
  }
  .sub_ttl {
    font-size: 2rem;
  }
  .news {
    background: #f7f6f0;
    padding: 2rem 8rem 2rem 3rem;
    @include mq() {
      padding: 2rem;
    }
    .txt_new {
      font-weight: 700;
      width: 10%;
      @include mq() {
        width: 14%;
      }
    }
  } //news
  .about {
    margin-top: 12rem;
    @include mq() {
      margin-top: 5rem;
    }
    .sub_ttl {
      border-bottom: 2px solid #231815;
      padding: 0 2rem 1.6rem 4.8rem;
      position: relative;
      @include mq() {
        font-size: 4.8vw;
        margin-left: 2rem;
        padding: 0 2rem 1rem 4rem;
        width: 100%;
      }
      &:before {
        content: "";
        display: inline-block;
        background: #231815;
        position: absolute;
        width: 2px;
        height: 3.4em;
        transform: rotate(32deg);
        left: 18px;
        bottom: -6px;
      }
    } //sub_ttl
    .desc {
      background: #fff;
      border: 1px solid #707070;
      font-size: 1.4vw;
      line-height: 2;
      margin-top: 8rem;
      padding: 5.6vw 3.9vw 5.6vw 8.5vw;
      position: relative;
      z-index: -1;
      @media screen and (min-width:1230px) {
        font-size: 1.8rem;
        padding: 7rem 5rem 7rem 10rem;
      }
      @include mq() {
        font-size: 3.8vw;
        margin-top: 4rem;
        padding: 4rem 3rem 3rem;
      }
      p:last-of-type {
        margin-top: 3rem;
      }
    }
  } //about
  .meeting {
    position: relative;
    padding: 0 2rem;
    @include mq() {
      padding: 0;
    }
    img {
      width: 52%;
      @include mq() {
        position: absolute;
        bottom: -72%;
        right: 0;
        width: 88%;
        z-index: -2;
      }
    }
    .right {
      width: 50%;
      position: absolute;
      top: 22%;
      right: 20px;
      @include mq() {
        position: relative;
        width: 88%;
        top: 0;
        left: 0;
      }
    }
  } //meeting
  .desc_detail {
    position: relative;
    z-index: -3;
    margin-top: 4rem;
    margin-bottom: 30rem;
    @media screen and (max-width:1230px) and (min-width: 768px) {
      margin-bottom: 26vw;
    }
    @include mq() {
      margin-top: 12rem;
      margin-bottom: 28rem;
    }
    .skewed_bg {
      padding-bottom: 4rem;
      @media screen and (min-width: 1331px) {
        height: 98rem;
      }
      @media screen and (max-width:1330px) and (min-width: 1050px) {
        height: 74vw;
      }
      @media screen and (max-width:1049px) and (min-width: 768px) {
        height: 74rem;
      }
      @include mq() {
        height: 218vw;
      }
      &:before {
        bottom: 0%;
        height: 90%;
        background: rgba(230,230,230,.6);
        @include mq() {
          height: 97%;
        }
      }
      &:after {
        bottom: -24%;
        height: 40%;
        background: #fff;
        @include mq()  {
          height: 32%;
        }
      }
      &+img {
        position: absolute;
        bottom: 0;
        top: 36rem;
        left: 50%;
        transform: translateX(-50%);
        max-width: 35rem;
        width: 35%;
        @media screen and (max-width:1230px) and (min-width: 1050px) {
          top: 26vw;
        }
        @media screen and (max-width:1049px) and (min-width: 768px) {
          top: 25rem;
        }
        @include mq() {
          width: 80%;
          top: 94vw;
        }
      }
    }
    .ttl {
      color: #fff;
      font-size: 6.5rem;
      line-height: 1;
      padding-top: 20rem;
      text-align: center;
      width: 100%;
      @media screen and (max-width:1230px) and (min-width: 1050px) {
        font-size: 5vw;
        padding-top: 14vw;
      }
      @media screen and (max-width:1049px) and (min-width: 768px) {
        padding-top: 12rem;
      }
      @media screen and (max-width:1049px) {
        font-size: 5rem;
      }
      @include mq() {
        padding-top: 36vw;
      }
      .small {
        font-size: 2rem;
        letter-spacing: 2px;
        @media screen and (max-width:1230px) and (min-width: 1050px) {
          font-size: 1.5vw;
        }
        @media screen and (max-width:1049px) {
          font-size: 1.6rem;
        }
        @include mq() {
          display: inline-block;
          line-height: 1.4;
          margin-top: 3rem;
        }
      }
      .large {
        font-weight: 300;
        letter-spacing: -1.3px;
        @include mq() {
          display: inline-block;
          margin-top: 1rem;
          line-height: 1.1;
        }
      }
    }
  }
  .sec_ttl {
    width: 27rem;
    &+p {
      margin-top: 4rem;
      text-align: center;
      @include mq() {
        width: 86%;
        margin: 4rem auto 0;
      }
    }
  }
  .project_info {
    margin-top: 12rem;
    .project_list {
      margin-top: 8rem;
      @include mq() {
        margin: 5rem 2rem 0;
      }
      li:not(:first-of-type) {
        margin-top: 14rem;
        @include mq() {
          margin-top: 5rem;
        }
      }
    }
    .list01 .right {
      top: 5rem;
      @include mq() {
        top: -2rem;
      }
    }
  } //project_info
  .asomanabo_space {
    .sec_ttl {
      width: 34rem;
    }
  }
  .message {
    margin-top: 11rem;
    @include mq() {
      margin-top: 6rem;
    }
    .skewed_bg {
      padding-bottom: 4rem;
      &:before {
        bottom: 0%;
        height: 90%;
        background: rgba(230,230,230,.6);
        @include mq() {
          height: 97%;
        }
      }
      &:after {
        bottom: -24%;
        height: 40%;
        background: #fff;
        @include mq()  {
          height: 32%;
        }
      }
    }
    .img_person {
      width: 31.5rem;
    }
    .inner {
      padding: 10rem 2rem 12rem;
      @include mq() {
        padding: 12rem 2rem 6rem;
      }
    }
    .bg_cloud {
      top: -5rem;
      left: -8rem;
      @include mq() {
        top: -4rem;
        left: 4rem;
       }
      .txt {
        padding: 2.6rem 0 0 4rem;
        line-height: 1.4;
        span {
          display: inline-block;
        }
      }
      .en, .posi {
        font-size: 1.4rem;
      }
      .posi {
        margin-top: 1.6rem;
        @include mq() {
          margin-top: 1rem;
        }
      }
      .name {
        font-size: 3rem;
      }
    } //bg_cloud
    .txt_area {
      width: calc(100% - 36rem);
      @include mq() {
        width: 100%;
        position: relative;
        top: -3rem;
      }
    }
    .msg {
      font-size: 2rem;
      font-weight: 700;
    }
    .btn_wrap {
      @include mq() {
        margin-top: 5rem;
      }
    }
  } //message
  .asomanabo_space {
    margin-bottom: 8rem;
    @include mq() {
      margin-bottom: 4rem;
    }
    .common_box {
      margin: 6rem 2rem 0;
    }
    .btn_wrap span.pd_s {
      padding-left: 3rem;
    }
    .inner {
      padding: 0 2rem;
      @include mq() {
        padding: 0;
      }
    }
    .txt_area {
      padding: 6rem;
      width: 53%;
      @include mq() {
        order: 2;
        padding: 3rem 2rem 0;
        width: 100%;
      }
      p {
        margin-bottom: 3rem;
      }
    }
    iframe {
      width: 47%;
      @include mq() {
        width: 100%;
      }
    }
  }
} //page_top
