@charset "UTF-8";
@import "vars.scss";
.page_privacy {
  .inner {
    width: 80rem;
    margin: 6rem auto 10rem;
    @include mq() {
      width: 90%;
    }
  }
  h2 {
    font-weight: 700;
    margin-top: 5rem;
  }
  p {
    margin-top: 1rem;
  }
}