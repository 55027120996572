@charset "UTF-8";
@import "vars.scss";
.page_cat {
  .inner {
    margin: 6rem auto;
    width: 76.5rem;
    @include mq() {
      width: 90%;
    }
  }
  .tag_area {
    border: 1px solid #707070;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    @include mq() {
      flex-wrap: wrap;
    }
    .cat-item {
      font-size: 1.4rem;
      text-align: center;
      width: calc(100% / 5);
      @include mq() {
        width: 50%;
      }
      &:not(:first-of-type) {
        border-left: 1px solid #707070;
        @include mq() {
          border-left: none;
          border-top: 1px solid #707070;
        }
      }
      &:not(:first-of-type):nth-of-type(2n+1) {
        @include mq() {
          border-left: 1px solid #707070;
        }
      }
      &.current-cat {
        background: #000;
        color: #fff;
      }
      a {
        padding: 1rem 0;
        display: inline-block;
        width: 100%;
      }
    }
    .cat-item.cat_all {
      @include mq() {
        width: 100%;
      }
    }
  } //tag_area
  .post_list {
    margin-top: 5rem;
    li {
      position: relative;
      margin-top: 2rem;
    }
    figure {
      display: inline-block;
      width: 30rem;
      height: 21.7rem;
      @include mq() {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .cont_wrap {
      display: inline-block;
      width: calc(100% - 32rem);
      padding-left: 2rem;
      vertical-align: top;
      @include mq() {
        width: 100%;
        padding-left: 0;
      }
      .btn_wrap {
        @include mq() {
          margin-top: 2rem;
          margin-block-end: 3rem;
        }
      }
    } //cont_wrap
    .ttl {
      font-size: 1.8rem;
      font-weight: 700;
    }
    .date {
      font-size: 1.2rem;
      margin-right: 1rem;
    }
    .cat {
      border: 1px solid #000;
      border-radius: 3em;
      display: inline-block;
      font-size: 1rem;
      padding: 1px 8px;
    }
    .cont {
      font-size: 1.3rem;
      margin-top: 1rem;
    }
    .btn_wrap {
      position: absolute;
      bottom: 0;
      right: 0;
      @include mq() {
        position: static;
      }
    }
  } //post_list
} //page_cat

.page_single {
  .inner {
    margin: 5rem auto 6rem;
    width: 76.5rem;
    @include mq() {
      width: 90%;
    }
    &>.note {
      .date {
        font-size: 1.4rem;
        font-weight: 700;
        margin-right: 1rem;
      }
      .cat {
        border: 1px solid #000;
        border-radius: 3em;
        display: inline-block;
        font-size: 1.3rem;
        font-weight: 700;
        padding: 1px 1rem;
      }
    }
  }
  .ttl {
    font-size: 2.8rem;
    font-weight: 700;
    margin-top: 3rem;
    @include mq() {
      font-size: 2.4rem;
    }
    &+figure {
      margin-top: 3rem;
      height: 50rem;
      @include mq() {
        height: auto;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .cont {
    margin-top: 5rem;
    h2, h3 {
      font-weight: 700;
      margin-bottom: 3rem;
    }
    h2 {
      border-left: 6px solid #5D5D5D;
      font-size: 2rem;
      line-height: 1.4;
      padding-left: 1rem;
    }
    p {
      margin-bottom: 3rem;
    }
    a {
      text-decoration: underline;
    }
    ul, ol {
      border: 1px solid #707070;
      padding: 1rem 2rem 1rem 4rem;
    }
    li {
      list-style: disc;
    }
  } //cont
} //page_single