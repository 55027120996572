@charset "utf-8";
@import "vars.scss";
.gnav_wrap {
  background: #fff;
  border-right: solid 1px #e6e6e6;
  color: #585858;
  font-weight: 600;
  width: 230px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  @include mq() {
    background: none;
    width: 24%;
    height: 8rem;
    border-right: none;
    left: inherit;
    right: 0;
    &:before {
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10rem 10rem 0;
      border-color: transparent #f7f6f0 transparent transparent;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  @include mq() {
    .sp_menu {
      width: 2.6rem;
      height: 2rem;
      position: absolute;
      right: 1.6rem;
      top: 32%;
      z-index: 99;
      margin: -9px 0 0;
      display: block;
      p {
        transition: .3s all;
        color: #333333;
      }
      span {
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 1rem;
        background: #000;
        left: 0;
        transition: all .3s;
        &:first-of-type {
          top: 2px;
        }
        &:nth-of-type(2) {
          top: 50%;
        }
        &:last-of-type {
          bottom: 0;
        }
      }
    }
  }
  .img_logo {
    padding: 3rem 2rem;
  }
  nav {
    @include mq() {
      background: #fff;
      display: block;
      position: fixed;
      right: -100%;
      top: 0;
      width: 50%;
      height: 100%;
      margin: 0 0 0;
      transition: .3s all;
      z-index: 98;
      padding-bottom: 3rem;
    }
  }
  @include mq() {
    .sp_open {
      &+nav {
        right: 0;
        overflow-y: scroll;
        -webkit-overflow-scrolling : touch;
      }
      &.sp_menu {
        span {
          transition: all .3s;
          &:first-of-type {
            top: 9px;
            transform: rotate(-45deg);
          }
          &:nth-of-type(2) {
            width: 0;
          }
          &:last-of-type {
            bottom: 9px;
            transform: rotate(45deg);
          }
        }
      }
    } //sp_open
  }
  .menu {
    font-size: 1.3rem;
    .upper {
      border-top: 1px solid #f0f0f0;
      padding: 2rem 4rem;
    }
    .lower {
      margin-top: 1rem;
      text-indent: 1em;
      li {
        line-height: 2.4;
      }
    }
  }
  .follow_menu {
    padding: 0 4rem;
    display: flex;
    li {
      margin-right: 2rem;
      width: 2.3rem;
      height: 2.3rem;
    }
  }
} //header
