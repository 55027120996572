@charset "UTF-8";
html, body, h1, h2, h3, h4, h5, h6, a, p, span, em, small, strong, sub, sup, mark, del, ins, strike, abbr, dfn, blockquote, q, cite, code, pre, ol, ul, li, dl, dt, dd, div, section, article, main, aside, nav, header, hgroup, footer, img, figure, figcaption, address, time, audio, video, canvas, iframe, details, summary, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td { margin: 0; padding: 0; }

address { font-style: normal; }

* { box-sizing: border-box; }

*:before, *:after { box-sizing: inherit; }

h1, h2, h3, h4, h5, h6 { font-weight: normal; font-size: 100%; }

/* font-size 10px = 1rem */
html { font-size: 62.5%; -webkit-text-size-adjust: 100%; /*iphone safari文字調整*/ }

body { color: #333; font-size: 1.6rem; font-feature-settings: "palt"; font-family: 'Noto Sans JP', sans-serif; line-height: 1.8; }

article, aside, footer, header, nav, section, main { display: block; }

a, a:link, a:visited { color: inherit; text-decoration: none; }

a:hover, a:active, a:focus { text-decoration: none; }

nav, ul, ol, li { list-style: none; }

table { border-collapse: collapse; border-spacing: 0; }

input[type="submit"]:hover { cursor: pointer; }

/* table, th, td { border: 0 none; } */
img, video { max-width: 100%; }

img { border-style: none; width: 100%; }

blockquote, q { quotes: none; }

blockquote:after, blockquote:before, q:after, q:before { content: ""; content: none; }

button { cursor: pointer; }

[hidden] { display: none !important; }

[disabled] { cursor: not-allowed; }

/* フォームのリセット */
input, button, textarea, select, option { margin: 0; padding: 0; background: none; border: none; border-radius: 0; outline: none; -webkit-appearance: none; -moz-appearance: none; appearance: none; }

select::-ms-expand { display: none; }

/* プレースホルダー */
input:placeholder-shown, select:placeholder-shown, textarea:placeholder-shown { color: #ccc; }

input::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder { color: #ccc; }

input:-moz-placeholder, select:-moz-placeholder, textarea:-moz-placeholder { color: #ccc; opacity: 1; }

input::-moz-placeholder, select::-moz-placeholder, textarea::-moz-placeholder { color: #ccc; opacity: 1; }

input:-ms-input-placeholder, select:-ms-input-placeholder, textarea:-ms-input-placeholder { color: #ccc; }

input:placeholder, select:placeholder, textarea:placeholder { color: #ccc; }

/* デフォルトスタイルの時はコメントアウト */
input[type="radio"], input[type="checkbox"] { display: none; }

textarea { resize: none; }

strong { font-weight: normal; }

em { font-style: normal; }

dt { font-weight: normal; }

fieldset { border: 0 none; }

/* pc tel link off */
@media screen and (min-width: 769px) { a[href^="tel:"] { pointer-events: none; } }
