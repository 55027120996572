@charset "UTF-8";
/* ====================================================
Font
==================================================== */
/* ====================================================
breakpoint
==================================================== */
.page_design h2 + p { color: #3C3C3C; text-align: center; width: 73rem; margin: 2rem auto 0; }

@media screen and (max-width: 767px) { .page_design h2 + p { width: 90%; } }

.page_design .lead { margin-top: 7rem; text-align: center; }

@media screen and (max-width: 767px) { .page_design .lead { margin-top: 5rem; } }

.page_design .lead h2 { font-size: 1.8rem; font-weight: 700; }

.page_design .deform_border { font-size: 2.3rem; height: 6.3rem; margin: 0 auto; text-align: center; }

.page_design .deform_border span { display: inline-block; margin-top: 8px; }

.page_design .deform_border_s { width: 20rem; }

.page_design .deform_border_l { width: 48rem; }

@media screen and (max-width: 767px) { .page_design .deform_border_l { font-size: 2rem; width: 36rem; } }

.page_design .past_work { margin-top: 6rem; }

.page_design .past_work h2 { margin: 0 auto 5rem; }

.page_design .common_box .right { top: 6rem; }

@media screen and (max-width: 767px) { .page_design .common_box .right { top: -2rem; } }

.page_design .common_box .list02 { margin-top: 10rem; }

@media screen and (max-width: 767px) { .page_design .common_box .list02 { margin-top: 5rem; } }

.page_design .want_to { margin-top: 16rem; }

@media screen and (max-width: 767px) { .page_design .want_to { margin-top: 8rem; } }

.page_design .want_to h2 { margin: 0 auto 5rem; }

@media screen and (max-width: 767px) { .page_design .want_to h2 { margin: 0 auto 2rem; } }

.page_design .want_to .list { margin-top: 3rem; }

.page_design .want_to .list .inner { width: 88%; margin: 0 auto; padding: 14rem 0 6rem; }

@media screen and (max-width: 767px) { .page_design .want_to .list .inner { padding: 8rem 0 4rem; } }

.page_design .want_to .list .flex { align-items: flex-start; }

.page_design .want_to .list img { width: 38%; }

@media screen and (max-width: 767px) { .page_design .want_to .list img { order: 2; width: 84%; margin: 0 auto; } }

.page_design .want_to .list h3 { color: #fff; font-family: "Roboto", sans-serif; font-size: 6.5rem; font-weight: 300; }

.page_design .want_to .skewed_bg:before { bottom: 0%; height: 90%; background: rgba(230, 230, 230, 0.6); }

@media screen and (max-width: 767px) { .page_design .want_to .skewed_bg:before { height: 97%; } }

.page_design .want_to .skewed_bg:after { bottom: -24%; height: 40%; background: #fff; }

@media screen and (max-width: 767px) { .page_design .want_to .skewed_bg:after { height: 32%; } }

.page_design .want_to .skewed_bg:nth-child(odd):before { transform: skew(0, -3deg); }

.page_design .want_to .skewed_bg:nth-child(odd):after { transform: skew(0, 3deg); }

.page_design .want_to .skewed_bg:nth-child(even):before { transform: skew(0, 3deg); }

.page_design .want_to .skewed_bg:nth-child(even):after { transform: skew(0, -3deg); }

.page_design .want_to .txt_wrap { width: 56%; position: relative; }

@media screen and (max-width: 767px) { .page_design .want_to .txt_wrap { width: 100%; } }

.page_design .want_to .txt_wrap p { font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", Meiryo, メイリオ, Osaka, "MS PGothic", arial, helvetica, sans-serif; font-size: 1.4rem; margin-bottom: 1rem; }

.page_design .want_to .item02 .txt_wrap { top: -4rem; }

.page_design .want_to .item03 .txt_wrap { top: -4rem; }
