@charset "UTF-8";
@import "vars.scss";
.page_contact {
  .footer_area {
    border-top: 1px solid #707070;
  }
  .inner {
    width: 77rem;
    margin: 0 auto;
    @include mq() {
      width: 90%;
    }
  }
  .lead {
    margin-top: 7rem;
    text-align: center;
    @include mq() {
      margin-top: 5rem;
    }
    h2 {
      font-size: 1.8rem;
      font-weight: 700;
    }
    h2+p {
      color: #3C3C3C;
      text-align: center;
      width: 73rem;
      margin: 2rem auto 0;
      @include mq() {
        width: 90%;
      }
    }
  } //lead
  .form_area {
    background: #E6E6E6;
    font-size: 1.4rem;
    margin: 5rem 0 48rem;
    padding: 5rem 0 9rem;
    position: relative;
    .mark {
      color: #D4004E;
      font-size: 1.2rem;
      display: inline-block;
      margin-left: 1em;
    }
    dt, input, textarea {
      font-family: 'Noto Sans CJK JP', sans-serif;
      font-size: 1.4rem;
    }
    dt:not(:first-of-type) {
      margin-top: 4rem;
    }
    dd {
      height: 7rem;
      margin-top: 1rem;
      p {
        margin-bottom: 1rem;
      }
    }
    dd.check_area {
      height: auto;
    }
    dd.txtarea {
      height: 22.4rem;
    }
    a {
      text-decoration: underline;
    }
    input, textarea {
      background: #fff;
      height: 100%;
      width: 100%;
      padding: 2.4rem 3rem;
    }
    input.btn_submit {
      border: 1px solid #707070;
      border-radius: 3em;
      height: 5.6rem;
      width: 16rem;
      position: absolute;
      padding: 0;
      bottom: -3rem;
      left: 50%;
      transform: translateX(-50%);
    }
    .check_area {
      .mwform-checkbox-field-text {
        &:before {
          content: "";
          display: inline-block;
          width: 2.6rem;
          height: 2.6rem;
          background: #fff;
          vertical-align: middle;
          margin-right: 1rem;
        }
      } //mwform-checkbox-field-text
      input[type="checkbox"]:checked {
        &+.mwform-checkbox-field-text {
          &:before {
            background: #707070;
          }
        }
      }
    } //check_area
  } //form_area
} //page_contact

.page_contact.confirm {
  .form_area {
    padding: 5rem 0;
  }
  .mark {
    display: none;
  }
  dd, dd.txtarea {
    background: #fff;
    height: auto;
    padding: 2rem;
  }
  input, textarea {
    padding: 0;
  }
  input.btn_back {
    border: 1px solid #707070;
    border-radius: 3em;
    height: 5.6rem;
    width: 16rem;
    padding: 0;
  }
  .btn_area {
    margin-top: 5rem;
    text-align: center;
  }
  .confirm_none {
    display: none;
  }
}

.page_contact.thanks {
  .inner {
    width: 78rem;
    margin: 7rem auto 8rem;
    @include mq() {
      width: 90%;
    }
  }
  .attention {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2;
    letter-spacing: .05em;
  }
  .note {
    line-height: 2;
    margin-top: 6rem;
  }
  .btn_top_wrap {
    margin-top: 6rem;
  }
  .btn_top {
    border: 1px solid #707070;
    border-radius: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.6rem;
    width: 16rem;
    padding: 0;
    margin: 0 auto;
  }
}