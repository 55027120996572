@charset "utf-8";
@import "vars.scss";
.footer_area {
  .post_area {
    background: #f7f6f0;
    padding: 4rem 2rem 0;
    .flex_row {
      justify-content: flex-start;
    }
  } //post_area
  .footer_contact {
    img, .txt_area {
      width: 50%;
      @include mq() {
        width: 100%;
      }
    }
    .txt_area {
      background: #212121;
      color: #fff;
      text-align: center;
      @include mq() {
        padding: 3rem 2rem;
        .ttl+p {
          text-align: left;
        }
      }
    }
    .ttl {
      font-size: 2rem;
      background: url('../img/deformed_border_white.png') no-repeat;
      background-position-y: center;
      background-size: contain;
      display: inline-block;
      padding: 3px 3rem;
      margin-bottom: 2rem;
    }
  }
  footer {
    width: 70rem;
    margin: 5rem auto;
    text-align: center;
    @include mq() {
      width: 100%;
      margin: 0 auto 5rem;
    }
    .logo {
      margin: 6rem 0;
      width: 18rem;
      @include mq() {
        margin: 3rem 0;
      }
    }
    .nav_menu.flex_row {
      @include mq() {
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
      }
    }
    .nav_menu li {
      @include mq() {
        border-bottom: 1px solid #CECECE;
        width: 50%;
        padding: 2rem 0;
        &:nth-of-type(even) {
          border-left: 1px solid #CECECE;
        }
      }
    }
  } //footer
  nav, address {
    color: #585858;
  }
  .fllow_wrap {
    color: #0f0f0f;
    margin-top: 6rem;
    text-align: center;
    @include mq() {
      margin-top: 3rem;
    }
    .follow_menu {
      width: 7rem;
      margin: 1rem auto 0;
      @include mq() {
        width: 9rem;
      }
    }
    .icon {
      width: 2.2rem;
      @include mq() {
        margin: 0 1rem;
      }
    }
  } //fllow_wrap
  .sns_menu {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 4.6rem 0 3rem;
    @include mq() {
      margin: 3rem 0;
    }
  }
  .fb_iframe_widget, #twitter-widget-0 {
    margin-right: 4px;
  }
  small {
    color: #707070;
  }
} //footer_area