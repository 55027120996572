@charset "utf-8";
@import "vars.scss";
.flex, .flex_row {
  display: flex;
  justify-content: space-between;
}
.flex {
  @include mq() {
    flex-direction: column;
  }
}
.flex_wrap {
  flex-wrap: wrap;
}
.flex_c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt_c {
  text-align: center;
}

.bold {
  font-weight: 700;
}

.sp {
  display: none;
}
@media screen and (max-width: 767px) {
  .pc {
    display: none;
  }
  .sp {
    display: block;
  }
}

/* =================
font
================= */
.hiragino {
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
}
.roboto {
  font-family: 'Roboto', sans-serif;
}

/* =================
bg_cloud
================= */
.bg_cloud {
  position: relative;
  .txt {
    position: absolute;
  }
  &.cloud_l {
    background: url('../img/cloud_l.png') no-repeat;
    background-size: contain;
    width: 32rem;
    height: 15rem;
  }
  &.cloud_s {
    background: url('../img/cloud_s.png') no-repeat;
    background-size: contain;
  }
  &.cloud_m {
    background: url('../img/cloud_m.png') no-repeat;
    background-size: contain;
  }
  &.cloud_ms {
    background: url('../img/cloud_ms.png') no-repeat;
    background-size: contain;
    width: 25rem;
    height: 11.5rem;
  }
}

/* =================
deform_border
================= */
.deform_border_s {
  background: url('../img/deformed_border01.png') no-repeat;
  background-size: contain;
}
.deform_border_l {
  background: url('../img/deformed_border02.png') no-repeat;
  background-size: contain;
}

/* =================
title
================= */
.sec_ttl {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 2rem;
  letter-spacing: .1em;
  margin: 0 auto;
  text-align: center;
  &:before, &:after {
    content: "";
    display: block;
    background: #231815;
    height: 1px;
  }
  &:before {
    margin-bottom: 1.4rem;
    transform: rotate(-3deg);
  }
  &:after {
    margin-top: 1.4rem;
    transform: rotate(3deg);
  }
}

.page_ttl {
  margin-top: 3rem;
  width: 31rem;
}

.page_ttl_l {
  margin-top: 3rem;
  width: 34rem;
}

/* =================
skew background
================= */
.skewed_bg {
  position: relative;
  overflow: hidden;
  &:before, &:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    z-index: -2;
  }
  &:before {
    transform: skew(0, 3deg);
  }
  &:after {
    transform: skew(0, -3deg);
  }
}

/* =================
btn
================= */
.btn_wrap {
  margin-top: 3rem;
  text-align: right;
  @include mq() {
    text-align: center;
  }
  span {
    padding-left: 4.6rem;
  }
}
.btn_more {
  background: url('../img/btn_more.png') no-repeat;
  background-size: contain;
  color: #7c7c7c;
  display: inline-block;
  font-size: 1.4rem;
  width: 21rem;
  height: 5rem;
  span {
    display: flex;
    align-items: center;
    height: 88%;
  }
} //btn_more