@charset "UTF-8";
@import "vars.scss";
.page_design {
  h2+p {
    color: #3C3C3C;
    text-align: center;
    width: 73rem;
    margin: 2rem auto 0;
    @include mq() {
      width: 90%;
    }
  }
  .lead {
    margin-top: 7rem;
    text-align: center;
    @include mq() {
      margin-top: 5rem;
    }
    h2 {
      font-size: 1.8rem;
      font-weight: 700;
    }
  } //lead
  .deform_border {
    font-size: 2.3rem;
    height: 6.3rem;
    margin: 0 auto;
    text-align: center;
    span {
      display: inline-block;
      margin-top: 8px;
    }
  }
  .deform_border_s {
    width: 20rem;
  }
  .deform_border_l {
    width: 48rem;
    @include mq() {
      font-size: 2rem;
      width: 36rem;
    }
  }
  .past_work {
    margin-top: 6rem;
    h2 {
      margin: 0 auto 5rem;
    }
  }
  .common_box {
    .right {
      top: 6rem;
      @include mq() {
        top: -2rem;
      }
    }
    .list02 {
      margin-top: 10rem;
      @include mq() {
        margin-top: 5rem;
      }
    }
  }
  .want_to {
    margin-top: 16rem;
    @include mq() {
      margin-top: 8rem;
    }
    h2 {
      margin: 0 auto 5rem;
      @include mq() {
        margin: 0 auto 2rem;
      }
    }
    .list {
      margin-top: 3rem;
      .inner {
        width: 88%;
        margin: 0 auto;
        padding: 14rem 0 6rem;
        @include mq() {
          padding: 8rem 0 4rem;
        }
      }
      .flex {
        align-items: flex-start;
      }
      img {
        width: 38%;
        @include mq() {
          order: 2;
          width: 84%;
          margin: 0 auto;
        }
      }
      h3 {
        color: #fff;
        font-family: $roboto;
        font-size: 6.5rem;
        font-weight: 300;
      }
    } //list
    .skewed_bg {
      &:before {
        bottom: 0%;
        height: 90%;
        background: rgba(230,230,230,.6);
        @include mq() {
          height: 97%;
        }
      }
      &:after {
        bottom: -24%;
        height: 40%;
        background: #fff;
        @include mq()  {
          height: 32%;
        }
      }
      &:nth-child(odd) {
        &:before {
          transform: skew(0, -3deg);
        }
        &:after {
          transform: skew(0, 3deg);
        }
      }
      &:nth-child(even) {
        &:before {
          transform: skew(0, 3deg);
        }
        &:after {
          transform: skew(0, -3deg);
        }
      }
    } //skewed_bg
    .txt_wrap {
      width: 56%;
      position: relative;
      @include mq() {
        width: 100%;
      }
      p {
        font-family: $hiragino;
        font-size: 1.4rem;
        margin-bottom: 1rem;
      }
    }
    .item02 {
      .txt_wrap {
        top: -4rem;
      }
    }
    .item03 {
      .txt_wrap {
        top: -4rem;
      }
    }
  } //want_to
} //page_design