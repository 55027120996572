@charset "UTF-8";
@import "vars.scss";
body {
  min-width: 1040px;
  @media screen and (max-width: 767px) {
    min-width: 0;
  }
}

.main {
  width: 100%;
  padding: 0 0 0 230px;
  @media screen and (max-width: 767px) {
    padding: 0;
  }
  &>.top_border {
    background: #585858;
    height: 6px;
    width: 100%;
  }
}

#breadcrumb {
  background: #E6E6E6;
  color: #333;
  font-size: 1.2rem;
  padding: 3rem 0 5rem;
  @include mq() {
    padding: 2rem 0 6rem;
  }
  &.skewed_bg {
    @include mq() {
      overflow: visible;
    }
  }
  &.skewed_bg:after {
    background: #fff;
    height: 74%;
    bottom: -35%;
    z-index: 2;
    @include mq() {
      height: 62%;
      bottom: -20%;
    }
  }
  ul {
    width: 70%;
    margin: 0 auto;
  }
  li {
    display: inline-block;
  }
}

.common_box {
  .left {
    width: 52.5%;
    position: relative;
    right: -2rem;
    @include mq() {
      width: 100%;
      right: 0;
    }
  }
  .right {
    width: 51.4%;
    position: relative;
    left: -2rem;
    @include mq() {
      width: 100%;
      left: 0;
      top: -2rem;
      margin: 0 auto;
    }
  }
  .bg_cloud {
    span {
      display: block;
    }
    &.cloud_s, &.cloud_m {
      line-height: 1.4;
      text-align: center;
      .in_txt {
        position: relative;
        top: 1.4rem;
      }
    }
    &.cloud_s {
      width: 22rem;
      height: 8.6rem;
      @include mq() {
        margin: 0 auto;
        top: -3rem;
      }
      .en {
        font-weight: 700;
      }
      .ja {
        font-size: 2.6rem;
      }
    } //cloud_s
    &.cloud_m {
      width: 27rem;
      height: 8.6rem;
      @include mq() {
        margin: 0 auto;
        top: -3rem;
      }
      .large {
        font-size: 2.6rem;
      }
    }
  }
  .ttl {
    border-bottom: 3px solid #585858;
    color: #212121;
    font-size: 2rem;
    font-weight: 700;
    padding: 2rem 6rem 2rem 10rem;
    @include mq() {
      padding: 0 0 3rem;
      text-align: center;
    }
    &+p {
      margin: 3rem 0 0 10rem;
      @include mq() {
        margin: 3rem 0 0;
      }
    }
  }
  .no {
    background: #000;
    border-radius: 3em;
    color: #fff;
    line-height: 1;
    position: absolute;
    top: -2.4rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 1rem 1.3rem;
  }
} //common_box

.page_design .common_box {
  .ttl {
    padding: 2rem 0rem 2rem 8rem;
    @include mq() {
      padding: 0 0 3rem;
    }
  }
}

.pagenavi {
  text-align: center;
  margin-top: 10rem;
  li {
    display: inline-block;
    line-height: 1;
  }
  a, span {
    display: inline-block;
    vertical-align: bottom;
    padding: 0 3px;
  }
  .next.page-numbers,
  .prev.page-numbers {
    position: relative;
    border: 1px solid #707070;
    display: inline-block;
    width: 4rem;
    height: 4rem;
    top: -6px;
    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      border-top: 2px solid #707070;
      border-left: 2px solid #707070;
      width: 1rem;
      height: 1rem;
      top: 1.4rem;
    }
  }
  .next.page-numbers {
    float: right;
    &:after {
      transform: translateX(-64%) rotate(135deg);
      right: 1rem;
    }
  }
  .prev.page-numbers {
    float: left;
    &:after {
      left: 1.6rem;
      transform: rotate(-45deg);
    }
  }
} //pagenavi

.page_single .pagenavi,
.page_agency .pagenavi {
  @include mq() {
    margin-top: 0;
  }
  li {
    width: 30%;
    @include mq() {
      width: 100%;
      margin-top: 5rem;
    }
  }
  .next, .prev {
    a {
      position: relative;
      &:after, &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: -1rem;
      }
      &:before {
        border: 1px solid #707070;
        display: inline-block;
        width: 4rem;
        height: 4rem;
      }
      &:after {
        border-top: 2px solid #707070;
        border-left: 2px solid #707070;
        width: 1rem;
        height: 1rem;
        top: 5px;
      }
    }
  } //.next, .prev
  .prev {
    text-align: right;
    a {
      padding-right: 5rem;
      &:before {
        right: 0;
      }
      &:after {
        right: 1.6rem;
        transform: rotate(135deg);
      }
    }
  } //next
  .next {
    text-align: left;
    a {
      padding-left: 5rem;
      &:before {
        left: 0;
      }
      &:after {
        left: 1.8rem;
        transform: rotate(-45deg);
      }
    }
  } //prev
  &:after,
  .empty {
    content: "";
    display: block;
    width: 30%;
  }
} //.page_single .pagenavi

.common_post {
  li {
    font-weight: 700;
    @include mq() {
      width: 100%;
    }
  }
  figure {
    max-width: 11rem;
    width: 32%;
    height: 7.4rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .txt_area {
    margin-left: 1rem;
    line-height: 1.6;
    position: relative;
    width: 66%;
    @include mq() {
      width: calc(100% - 13rem);
    }
  }
  .note {
    font-size: 1rem;
    margin-top: 1px;
    position: absolute;
    bottom: 0;
  }
  .date {
    margin-right: 1rem;
    @include mq() {
      font-size: 1.4rem;
    }
  }
  .cat {
    border: 1px solid #333;
    border-radius: 1em;
    display: inline-block;
    padding: 0 5px;
    margin-top: 5px;
    @include mq() {
      font-size: 1.2rem;
    }
  }
}

.footer_area {
  .common_post {
    li {
      width: calc(100% / 3.1);
      margin-bottom: 4rem;
      @include mq() {
        width: 100%;
      }
    }
  }
}

.page_top .news {
  .common_post {
    width: 90%;
    @include mq() {
      width: 86%;
    }
    li {
      width: calc(100% / 2.1);
      @include mq() {
        width: 100%;
      }
    }
    .txt_area {
      width: calc(100% - 58%);
      min-width: calc(100% - 11rem);
    }
  }
}

.page_privacy .footer_area {
  border-top: 1px solid #707070;
}

.consult_design {
  background: rgba(230,230,230,.6);
  margin-top: 5rem;
  padding: 4.6rem 15rem;
  @include mq() {
    padding: 3rem 0
  }
  .box {
    background: #fff;
    border: 1px solid #707070;
    height: 32rem;
    max-width: 74rem;
    margin: 0 auto;
    text-align: center;
    @include mq() {
      width: 90%;
      margin: 0 auto;
    }
  }
  h2 {
    font-size: 2.2rem;
  }
  p.btn_wrap {
    margin-top: 3rem;
    text-align: center;
  }
} //consult_design

