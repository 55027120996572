@charset "UTF-8";
@import "vars.scss";
.page_message {
  .inner {
    max-width: 74rem;
    margin: 0 auto;
    @include mq() {
      max-width: inherit;
    }
  }
  .page_ttl {
    margin-top: 6rem;
    @include mq() {
      margin-top: 3rem;
    }
  }
  .common_box {
    .left {
      width: 27rem;
      left: 0;
      @include mq() {
        left: 2rem;
      }
    }
    .right {
      width: calc(100% - 26rem);
      right: 0;
      @include mq() {
        width: 90%;
        margin: 0 auto;
      }
    }
    .flex {
      margin: 18rem 0 5rem;
      @include mq() {
        margin: 12rem 0 5rem;
      }
    }
    .ttl {
      padding: 0 0 2rem 5rem;
      word-break: keep-all;
      @include mq() {
        word-break: normal;
        text-align: left;
        padding: 0 0 2rem;
      }
    }
    .ttl+p {
      margin: 3rem 0 0 5rem;
      @include mq() {
        margin: 3rem 0 0;
       }
    }
    .bg_cloud {
      top: -4rem;
      left: -1rem;
      @include mq() {
        top: -4rem;
        left: 4rem;
       }
      .txt {
        padding: 2.6rem 0 0 4rem;
        line-height: 1.4;
        span {
          display: inline-block;
        }
      }
      .en, .posi {
        font-size: 1.4rem;
      }
      .posi {
        margin-top: 1.6rem;
        @include mq() {
          margin-top: 1rem;
        }
      }
      .name {
        font-size: 3rem;
      }
    } //bg_cloud
  }
  .skewed_bg {
    padding-bottom: 50rem;
    &:before {
      bottom: 0%;
      height: 92%;
      background: rgba(230,230,230,.6);
      @include mq() {
        height: 96%;
      }
    }
    &:after {
      bottom: -30%;
      height: 38%;
      background: #fff;
      @include mq() {
      }
    }
  } //skewed_bg
  .message {
    margin: -46rem auto 0;
    position: relative;
    .deform_border {
      font-size: 2.3rem;
      position: absolute;
      top: -2.4rem;
      left: 6rem;
      width: 18.2rem;
      height: 6.3rem;
      text-align: center;
      .txt {
        display: inline-block;
        margin-top: 6px;
      }
    }
    .box {
      background: #fff;
      border: 1px solid #707070;
      padding: 7rem 6rem 4rem;
      @include mq() {
        width: 90%;
        margin: 0 auto;
        padding: 5rem 3rem 2rem;
      }
      p {
        margin-bottom: 3rem;
        @include mq() {
          font-size: 1.4rem;
        }
      }
      p.sign {
        font-size: 2.7rem;
        text-align: right;
      }
    }
  }
  .bottom_img {
    margin: 6.5rem auto;
    @include mq() {
      margin: 3rem auto;
    }
  }
} //page_message
