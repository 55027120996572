/* ====================================================
Font
==================================================== */
$hiragino: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif;
$roboto: 'Roboto', sans-serif;

/* ====================================================
breakpoint
==================================================== */
$breakpoint-sm: 400px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 1000px !default;

$breakpoints: (
  'sm': 'screen and (max-width: 399px)',
  'md': 'screen and (max-width: 767px)',
  'lg': 'screen and (max-width: 999px)',
  'xl': 'screen and (max-width: 1199px)',
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}